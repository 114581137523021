const {fr} = require("date-fns/locale");
const { registerLocale, setDefaultLocale } = require("react-datepicker");

registerLocale('fr', fr);
setDefaultLocale('fr');

module.exports = {
  "api_token": "unrelevant",
  "date_format": {
    "fr": "dd/MM/yyyy"
  },
  "display_contact": true,
  "fb_display": false,
  "info_traffic_title": {
    "fr": "Toute l'info trafic"
  },
  "last_news_label": {
    "fr": "Actualités"
  },
  "mapbox_token": "pk.eyJ1IjoibWltaXJvc2UyOSIsImEiOiJjbGV3cHhsaGgxMHV2NDNtazAxOGM0MXhrIn0.LieDdLfJevyJ6njBc9-vQg",
  "notifications_service_endpoint": "https://apim.keolis.com/KWP000/notification",
  "search_title": {
    "fr": "Recherche"
  },
  "side_blocks": {
    "fr": [
      "42031646"
    ]
  },
  "api_url": "unrelevant",
  "contact_label": {
    "fr": "Renseignements"
  },
  "info_traffic_slug": {
    "fr": "toutes-les-infos-trafic"
  },
  "news_redirect_label": {
    "fr": "Lire l'actualité"
  },
  "notifications_service_import_file_endpoint": "https://apim.keolis.com/KWP000/file/import",
  "search_button_label": {
    "fr": "Rechercher"
  },
  "time_format": {
    "fr": "HH:mm"
  },
  "twitter_display": false,
  "website_name": "Keolis Littoral",
  "contact_phone_number": {
    "fr": "05 46 82 31 20"
  },
  "info_traffic_detail_slug": {
    "fr": "Détail de l'info trafic"
  },
  "no_search_label": {
    "fr": "Veuillez saisir des termes et valider pour lancer la recherche"
  },
  "notifications_service_token": "1142321004f3407b98a95856f9820607",
  "primary_color": {
    "red": 0,
    "blue": 125,
    "alpha": 255,
    "green": 95
  },
  "alt_text": {
    "fr": "Renseignements : 05 46 82 31 20"
  },
  "gradient_primary_color": {
    "red": 0,
    "blue": 198,
    "alpha": 255,
    "green": 151
  },
  "info_traffic_content_title": {
    "fr": "L'information trafic"
  },
  "search_in_progress_label": {
    "fr": "Recherche en cours..."
  },
  "gradient_button_color": {
    "red": 12,
    "blue": 123,
    "alpha": 255,
    "green": 125
  },
  "redirection_button_text": {
    "fr": "+ D'INFOS"
  },
  "search_no_results_label": {
    "fr": "Il n'y a aucun résultat pour cette recherche"
  },
  "active_button_text_color": {
    "red": 255,
    "blue": 255,
    "alpha": 255,
    "green": 255
  },
  "search_error_label": {
    "fr": "Une erreur s'est produite, veuillez réessayer dans quelques minutes"
  },
  "search_token": "8ceaa4e32b8249fc706de50eb4a7e2",
  "secondary_color": {
    "red": 255,
    "blue": 255,
    "alpha": 255,
    "green": 255
  },
  "subsidiary_logo": {
    "path": "/49925/1693216151-keolis_littoral_blanc.svg",
    "format": "svg",
    "size": 3647,
    "alt": "Logo Keolis Littoral",
    "title": "Keolis Littoral",
    "width": 417,
    "height": 134
  },
  "back_to_home_title": {
    "fr": "Keolis Littoral – revenir à la page d'accueil"
  },
  "language_selector_title": {
    "fr": "Changer la langue"
  },
  "piano_site_id": "639224",
  "google_tag_manager_id": "GTM-PX2XQXM",
  "google_analytics_key": "",
  "didomi_api_key": "2127e597-3d3f-4aa2-9727-c8126f86298c",
  "actistat_key": "",
  "loader": {
    "path": "/49925/1693496659-loader-bleu-utilisateur.gif",
    "format": "gif",
    "size": 830108,
    "alt": "Page en chargement, veuillez patientez s'il vous plaît",
    "title": "Page en chargement",
    "width": 1080,
    "height": 1080
  },
  "api_url_get_lines_endpoint": "/public/line",
  "api_url_user_registration_endpoint": "/public/customer",
  "scripts": [],
  "form_captcha_key": "6Lcd1DMjAAAAAEsHVSyCqBSA_c8IBpjJStjgY_pH",
  "updated_at": "2024-02-08T11:46:14.186+01:00",
  "created_at": "2020-12-29T15:46:21.809+01:00",
  "locale": "fr",
  "locales": [
    "fr"
  ],
  "siteUrl": "https://qksakwplitewstatweb.z6.web.core.windows.net/",
  "readOnlyApi": "8227392899669c47999f9c98fe92ae"
}